import { Component, OnInit } from "@angular/core";
import { AuthService } from "../shared/auth.service";
import { Router } from "@angular/router";
import { UserAgentApplication } from "msal";
import { Adal6Service } from "adal-angular6";
import * as _ from "lodash";
import { AppSettingsService } from "../shared/app.settings.service";
import { LogService } from "../shared/app-loggers/app-log.service";
import { AppRegistration } from "../models/AuthzUserInfo.model";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public user: UserAgentApplication = null;
  public userInfo: any = null;
  public apiCallFailed: boolean;
  public loginFailed: boolean;
  public token: string;
  public authorizationStatusMessage: string;
  public app_name: string;

  constructor(
    public authService: AuthService,
    private router: Router,
    private adalService: Adal6Service,
    public appSettingsService: AppSettingsService,
    private spinner: NgxSpinnerService,
    private logger: LogService
  ) {
    console.log("Inside Home constructor");
  }

  ngOnInit() {

    this.login();
  }

  routeRole() {
    let roles = this.authService?.CurrentUser?.userTypeRoles;
    if (roles) {
      if (roles.isAdmin || roles.isReportManager) {
        this.router.navigate(["/file-submission-status"]);
      } else if (roles.isUser) {
        this.router.navigate(["/file-upload"]);
      } else {
        this.router.navigate(["/file-submission-status"]);
      }
    }
  }

  public login() {
    // Handle callback if this is a redirect from Azure
    this.adalService.handleWindowCallback();
    console.log(this.adalService.userInfo.username);
    if (
      this.authService["ForgotPasswordUsed"] === true &&
      !this.adalService.userInfo.error
    ) {
      this.authService["ForgotPasswordUsed"] = false;
      alert("Password updated successfully, please re-login!!");
      this.logger.info("Home: User Password updated successfully");
      this.adalService.logOut();
      return;
    }

    // Check if the user is authenticated. If not, call the login() method
    if (!this.adalService.userInfo.authenticated) {
      this.logger.warn("Home: User not authenticated, calling login again");
      this.adalService.login();
    }

    this.authService.init(this.adalService);
    this.authService.loginSubject.subscribe(
      (x) => {
        const currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
        if (currentUser !== undefined && currentUser !== null) {
          this.authService["CurrentUser"] = currentUser;
        }
        if (x) {
          let app: AppRegistration =
            this.authService["CurrentUser"].appRegistration ||
            <AppRegistration>{};
          if (Object.keys(sessionStorage).indexOf("adal.login.request") > 0) {
            const adal_login_request =
              sessionStorage.getItem("adal.login.request");
            let isClientApp = adal_login_request.indexOf("client_id") != -1;
            if (isClientApp) {
              this.spinner.hide()
              if (app.tenantName == "iar_dashboard_gia-portal.cbre.com") {
                this.router.navigate(["/tenant-app-dashboard"]);
              } else if (
                app.tenantName == "missing_enrichment_gia-portal.cbre.com"
              ) {
                this.router.navigate(["/missing-enrichment"]);
              } else if (
                app.tenantName ==
                "level3_validation_approve_reject_gia-portal.cbre.com"
              ) {
                if (currentUser.userRolesList[0]["userTypeTitle"] == "User") {
                  // currentUser.appSettings.find(x=> x.appSettingName == 'UN_AUTHORIZED_MSG')['appSettingValue']
                  this.authorizationStatusMessage =
                    currentUser.appSettings.find(
                      (x) =>
                        x.appSettingName == "UN_AUTHORIZED_MSG_SELF_VALIDATION"
                    )["appSettingValue"];
                }
                this.router.navigate(["/validation-approval"]);
              } else {
                this.routeRole();
                //this.router.navigate(["/file-upload"]);
              }
            } else {
              this.routeRole();
              //this.router.navigate(["/file-upload"]);
            }
          } else {
            this.routeRole();
            //this.router.navigate(["/file-upload"]);
          }
        } else {
          if (
            this.authService.authorizationStatusMessage == "TOO_MANY_REQUESTS"
          ) {
            this.authorizationStatusMessage = currentUser.appSettings.find(
              (x) => x.appSettingName == "TOO_MANY_REQUESTS"
            )["appSettingValue"];
          } else {
            this.authorizationStatusMessage =
              this.authService.authorizationStatusMessage;
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
